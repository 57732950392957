
const state = {
    activeTab: 0,
    activeTabDraft: 0,
    activeTabReleased: 0,
    activeTabInactive: 0,
    customerVisitPlanID: null,
    customerVisitPlanLineID: null,
    customerVisitID: null,
}

const mutations = {
    setActiveTab(state, val) {
        state.activeTab = val
    },
    setActiveTabDraft(state, val) {
        state.activeTabDraft = val
    },
    setActiveTabReleased(state, val) {
        state.activeTabReleased = val
    },
    setActiveTabInactive(state, val) {
        state.activeTabInactive = val
    },
    setCustomerVisitPlanID(state, val) {
        state.customerVisitPlanID = val
    },
    setCustomerVisitPlanLineID(state, val) {
        state.customerVisitPlanLineID = val
    },
    setCustomerVisitID(state, val) {
        state.customerVisitID = val
    },

}

const actions = {
    updateActiveTab({ commit }, val) {
        commit('setActiveTab', val)
    },
    updateActiveTabDraft({ commit }, val) {
        commit('setActiveTabDraft', val)
    },
    updateActiveTabReleased({ commit }, val) {
        commit('setActiveTabReleased', val)
    },
    updateActiveTabInactive({ commit }, val) {
        commit('setActiveTabInactive', val)
    },
    updateCustomerVisitPlanID({ commit }, val) {
        commit('setCustomerVisitPlanID', val)
    },
    updateCustomerVisitPlanLineID({ commit }, val) {
        commit('setCustomerVisitPlanLineID', val)
    },
    updateCustomerVisitID({ commit }, val) {
        commit('setCustomerVisitID', val)
    },
    defaultActiveTab({ commit }) {
        commit('setActiveTab', 0)
    },
    defaultCustomerVisitPlanID({ commit }) {
        commit('setCustomerVisitPlanID', null)
    },
    defaultCustomerVisitPlanLineID({ commit }) {
        commit('setCustomerVisitPlanLineID', null)
    },
    defaultCustomerVisitID({ commit }) {
        commit('setCustomerVisitID', null)
    },

}

const getters = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
